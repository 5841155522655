import { EntityEnums } from "../enums";

export const logicDict = [
  {
    value: EntityEnums.Logic.Positive,
    label: "positive",
    info: "",
  },
  {
    value: EntityEnums.Logic.Negative,
    label: "negative",
    info: "",
  },
];
